import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, message, Skeleton, Button, Tag, Space } from "antd";
import { getProjectName } from "../../services/firebase";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { REPORT_TYPE } from "../../config/Const";
import userApi from "../../api/userApi";
import { removeEmptyField } from "../../utils/Helper";
import Text from "antd/lib/typography/Text";

const ReportUsersV2 = () => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const fetchData = () => {
    userApi
      .getReportList()
      .then((res) => {
        setLoading(false);
        setRequesting(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const onClickId = (record) => () => {
    let id = "";
    let collection = "";
    switch (record.type) {
      case REPORT_TYPE.CONFESSION:
        id = record.confessionId;
        collection = "confession";
        break;
      case REPORT_TYPE.TASK:
        id = record.taskId;
        collection = "tasks";
        break;
      case REPORT_TYPE.CONFESSION_COMMENT:
        id = record.commentId;
        collection = `confession~2F${record.confessionId}~2Fcomments`;
        break;

      default:
        break;
    }

    if (
      record.type === REPORT_TYPE.CHAT ||
      record.type === REPORT_TYPE.CHAT_MESSAGE
    ) {
      history.push({
        pathname: Routes.Room.path,
        state: record,
      });
      return;
    }
    window.open(
      `https://console.firebase.google.com/u/0/project/${getProjectName()}/firestore/data/~2F${collection}~2F${id}`
    );
  };

  const onDone = (record) => () => {
    setRequesting(true);
    userApi
      .maskReportDone(record.id)
      .then(() => {
        fetchData();
        message.success("Success!");
      })
      .catch((err) => {
        setRequesting(false);
        message.warn(err);
      });
  };

  const onBlock = (record) => () => {
    delete record.user;
    delete record.createdAt;
    delete record.updatedAt;
    delete record.status;
    delete record.reporterId;
    const object = removeEmptyField(record);
    const params = new URLSearchParams(object);
    history.push({
      pathname: Routes.BlockUserV2.path,
      search: `?${params}`,
    });
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      fixed: "left",
      width: 150,
      filters: [
        {
          text: "Chat",
          value: REPORT_TYPE.CHAT,
        },
        {
          text: "Confession",
          value: REPORT_TYPE.CONFESSION,
        },
        {
          text: "Confession Comment",
          value: REPORT_TYPE.CONFESSION_COMMENT,
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record?.type?.indexOf(value) === 0,
      render: (tag) => {
        let color = "";
        switch (tag) {
          case REPORT_TYPE.CHAT:
            color = "geekblue";
            break;
          case REPORT_TYPE.CONFESSION:
            color = "green";
            break;
          case REPORT_TYPE.CONFESSION_COMMENT:
            color = "volcano";
            break;
          case REPORT_TYPE.TASK:
            color = "yellow";
            break;

          default:
            break;
        }
        return (
          <Tag color={color} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Report Type",
      key: "reportType",
      render: (item) => <Text>{item.reportType || item.content}</Text>,
    },
    {
      title: "Tag Type",
      dataIndex: "tagType",
      key: "tagType",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 250,
      render: (record) => (
        <Space size="middle">
          <Button key={record.id} type="dashed" onClick={onDone(record)}>
            Done
          </Button>
          <Button key={record.id} type="primary" onClick={onBlock(record)}>
            Block
          </Button>
          <Button key={record.id} type="ghost" onClick={onClickId(record)}>
            Open
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Report Users</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <Table
          dataSource={data}
          columns={columns}
          scroll={{ x: 1300 }}
          loading={{ spinning: requesting }}
        />
      )}
    </>
  );
};
export default ReportUsersV2;
